<template>
  <v-card>
    <v-card-title>
      <span class="headline" v-if="isCreation"><v-icon>mdi-account-plus</v-icon>ユーザ追加</span>
      <span class="headline" v-if="!isCreation"><v-icon>mdi-account-edit</v-icon>ユーザ編集</span>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-form ref="userForm" v-model="validEntryForm" lazy-validation>
        <v-container>
          <v-row dense>
            <v-col cols="12" sm="6">
              <v-text-field label="ユーザID" :value="userModel.userId" filled dense disabled></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                label="氏名"
                :rules="[rules.required, rules.maxLength(30)]"
                v-model="userModel.userName"
                filled
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" v-if="(isMakerMode || isVendorMode) && isCafereoActions">
              <corporation-field
                :rules="[rules.required]"
                :group="entryCorporationType"
                :code="userModel.corporationCd"
                v-model="userModel.corporationCd"
              ></corporation-field>
            </v-col>
            <v-col cols="12" v-if="isCafereoMode">
              <department-field
                ref="department"
                label="所属"
                :rules="[rules.required]"
                :value="userModel.groupCd"
                @selected="onDepartmentChanged"
              ></department-field>
            </v-col>
            <v-col cols="12" sm="6" v-if="isMakerMode">
              <supplier-field
                v-model="userModel.groupCd"
                :corporate="userModel.corporationCd"
                :label="isCafereoActions ? '仕入先名' : '仕入先'"
                :rules="[rules.required]"
                :disabled="isMakerActions"
              ></supplier-field>
            </v-col>
            <v-col cols="12" sm="6" v-if="isVendorMode">
              <customer-field
                v-model="userModel.groupCd"
                :corporate="userModel.corporationCd"
                :label="isCafereoActions ? '取引先名' : '店舗名'"
                :rules="[rules.required]"
              ></customer-field>
            </v-col>
            <v-col cols="12" sm="6" v-if="isCafereoMode">
              <v-text-field
                label="連絡先電話番号"
                v-model="userModel.phoneNo"
                :rules="[rules.required, rules.phoneNo]"
                placeholder="XXX-XXXX-XXXX"
                filled
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" v-if="isCafereoMode || !isCafereoActions">
              <v-text-field
                label="メールアドレス"
                v-model="userModel.mailAddress"
                :rules="[rules.required, rules.maxLength(256), rules.mailAddress]"
                filled
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-select
                label="権限"
                v-model="userModel.privilege"
                :rules="[rules.privilege]"
                :items="availablePrivileges"
                :disabled="availablePrivileges.length === 0"
                filled
                dense
                append-icon="mdi-plus"
                attach
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                autocomplete="new-password"
                label="パスワード"
                :type="showPassword ? 'text' : 'password'"
                :rules="isCreation ? [rules.required, rules.password] : [rules.password]"
                v-model="userModel.password"
                filled
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                autocomplete="new-password"
                label="パスワード(再入力)"
                :type="showPassword ? 'text' : 'password'"
                :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                :rules="isCreation ? [rules.required, rules.password2] : [rules.password2]"
                @click:append="showPassword = !showPassword"
                v-model="userModel.password2"
                filled
                dense
              ></v-text-field>
            </v-col>
            <!-- <v-col cols="6" sm="3">
              <v-switch label="ロック" v-model="userModel.locked" inset dense></v-switch>
            </v-col> -->
            <v-col cols="6" sm="3">
              <v-switch label="無効化" v-model="userModel.inActivated" inset dense></v-switch>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn color="secondary" @click="onCancelClick">キャンセル</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="onCreateClick" v-if="isCreation">登録</v-btn>
      <v-btn color="primary" @click="onUpdateClick" v-if="!isCreation">更新</v-btn>
    </v-card-actions>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
    </v-overlay>
  </v-card>
</template>

<script>
import { CafereoPrivileges, MakerPrivileges, VendorPrivileges } from "../../consts/UserPrivileges";
import CorporationTypes from "../../consts/CorporationTypes";
import CafereoAffiliations from "../../consts/CafereoAffiliations";
import Validation from "../../libs/validation";
import { statuses as ApiStatus } from "../../libs/api-client";
import CorporationField from "../../components/common/fields/CorporationField.vue";
import DepartmentField from "../../components/common/fields/DepartmentField.vue";
import SupplierField from "../../components/common/fields/SupplierField.vue";
import CustomerField from "../../components/common/fields/CustomerField.vue";

export default {
  name: "UserEntry",
  props: ["inputModel", "entryCorporationType", "entryCorporationRoot"],
  components: {
    CorporationField,
    DepartmentField,
    SupplierField,
    CustomerField,
  },
  data() {
    return {
      rules: {
        required: Validation.required,
        maxLength: Validation.maxLength,
        phoneNo: Validation.phoneNo,
        mailAddress: Validation.mailAddress,
        privilege: (value) => this.availablePrivileges.length === 0 || Validation.required(value),
        password: Validation.password,
        password2: (value) => this.userModel.password === value || "パスワードが一致しません",
      },
      validEntryForm: null,
      showPassword: false,
      userModel: null,
      availablePrivileges: [],
    };
  },
  beforeMount() {
    this.init(this.inputModel);
  },
  watch: {
    inputModel(inputModel) {
      this.init(inputModel);
    },
    entryCorporationType() {
      this.reset();
    },
  },
  computed: {
    isCreation() {
      return Boolean(this.inputModel.createNew);
    },
    isCafereoMode() {
      return this.entryCorporationType === CorporationTypes.CAFEREO;
    },
    isMakerMode() {
      return this.entryCorporationType === CorporationTypes.MAKER;
    },
    isVendorMode() {
      return this.entryCorporationType === CorporationTypes.VENDOR;
    },
    isCafereoActions() {
      return this.entryCorporationRoot === CorporationTypes.CAFEREO;
    },
    isMakerActions() {
      return this.entryCorporationRoot === CorporationTypes.MAKER;
    },
    isVendorActions() {
      return this.entryCorporationRoot === CorporationTypes.VENDOR;
    },
    isLoading() {
      return this.$store.getters["ui/isLoading"];
    },
  },
  methods: {
    init(inputModel) {
      this.showPassword = false;
      this.initUserModel(inputModel);
      this.initPrivileges(inputModel);
      if (this.$refs.userForm) {
        this.$refs.userForm.resetValidation();
      }
    },
    reset() {
      this.init(this.inputModel);
    },
    initUserModel(inputModel) {
      if (inputModel.createNew) {
        this.userModel = {
          userId: "自動採番されます",
          userName: null,
          corporationCd: this.$store.getters["security/loggedInUser"].corporateCode,
          groupCd: this.isMakerActions ? this.$store.getters["security/loggedInUser"].groupCode : null,
          phoneNo: null,
          mailAddress: null,
          privilege: null,
          locked: false,
          inActivated: false,
          password: "",
          password2: "",
        };
      } else {
        this.userModel = {
          userId: inputModel.userRecord.userId,
          userName: inputModel.userRecord.userName,
          corporationCd: inputModel.userRecord.corporationCd
            ? inputModel.userRecord.corporationCd
            : this.$store.getters["security/loggedInUser"].corporateCode,
          groupCd: inputModel.userRecord.groupCd,
          phoneNo: inputModel.userRecord.phoneNo,
          mailAddress: inputModel.userRecord.mailAddress,
          privilege: inputModel.userRecord.privilege,
          //locked: inputModel.userRecord.locked,
          locked: false,
          inActivated: inputModel.userRecord.inActivated,
          password: "",
          password2: "",
          updateDate: inputModel.userRecord.updateDate,
        };
      }
    },
    initPrivileges(inputModel) {
      if (
        this.isCafereoMode &&
        inputModel.userRecord &&
        ![CafereoAffiliations.PRESIDENT, CafereoAffiliations.FTRANS].includes(inputModel.userRecord.affiliationGroup)
      ) {
        this.availablePrivileges = CafereoPrivileges.all();
      } else if (this.isMakerMode) {
        this.availablePrivileges = MakerPrivileges.all();
      } else if (this.isVendorMode) {
        this.availablePrivileges = VendorPrivileges.all();
      } else {
        this.availablePrivileges = [];
      }
    },
    onDepartmentChanged(groupCd) {
      if (this.userModel.groupCd != groupCd) {
        this.userModel.groupCd = groupCd;
        this.userModel.privilege = null;

        const selected = this.$refs.department.selected();
        if (
          !selected ||
          ![CafereoAffiliations.PRESIDENT, CafereoAffiliations.FTRANS].includes(selected.affiliationGroup)
        ) {
          this.availablePrivileges = CafereoPrivileges.all();
        } else {
          this.availablePrivileges = [];
        }
      }
    },
    async onCreateClick() {
      if (this.validate()) {
        try {
          this.loadingOn();
          const userModel = { corporationType: this.entryCorporationType, ...this.userModel };
          delete userModel.userId;
          delete userModel.password2;
          const response = await this.$store.dispatch("user/create", userModel);
          if (ApiStatus.isSystemError(response.data?.header)) {
            return this.redirectError();
          }
          this.$dialog.notify.info(`ユーザを登録しました (${response.data.contents.userId})`);
          this.$emit("created");
          this.reset();
        } catch (error) {
          console.error("UserEntry::onCreateClick", error);
          this.apiRequestError(error);
        } finally {
          this.loadingOff();
        }
      }
    },
    async onUpdateClick() {
      if (this.validate()) {
        try {
          this.loadingOn();
          const userModel = { corporationType: this.entryCorporationType, ...this.userModel };
          delete userModel.password2;
          const response = await this.$store.dispatch("user/update", userModel);

          switch (response.data?.header.resultCode) {
            case ApiStatus.consts.SUCCESS:
              this.$emit("updated", response.data.contents.user);
              this.reset();
              break;
            case ApiStatus.consts.ALREADY_CHANGED:
              this.$dialog.warning({
                title: `ユーザ編集`,
                text: response.data.header.messages[this.userModel.userId].join(),
                actions: ["OK"],
              });
              break;
            default:
              this.redirectError();
              break;
          }
        } catch (error) {
          console.error("UserEntry::onUpdateClick", error);
          this.apiRequestError(error);
        } finally {
          this.loadingOff();
        }
      }
    },
    onCancelClick() {
      this.$emit("cancel");
      this.reset();
    },
    validate() {
      const isValid = this.$refs.userForm.validate();
      if (!isValid) {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
      }
      return isValid;
    },
  },
};
</script>
